<template>
  <div>
    <main class="payment-method-table">
      <div class="header">
        <div class="subtitle">
          <p>Métodos de pagamento e recebimento</p>
          <v-help-circle class="icon" id="subtitle-tooltip-payment_methodd" />
        </div>
        <Loading
          :class="{ reload: true}"
          @click="() => !loading && getPaymentMethods()"
        />
      </div>

      <b-tooltip
        target="subtitle-tooltip-payment_methodd"
        triggers="hover"
        placement="bottom"
      >
        <p class="order-body">
          Tenha controle de todos os métodos de pagamento da sua clínica,
          informe o número máximo de parcelas permitidas para cada método e se o
          recebimento é automático (situação é alterada para “pago” e as
          parcelas são recebidas automaticamente).
        </p>
      </b-tooltip>

      <div class="outages">
        <table>
          <thead class="data-head">
            <tr>
              <th class="head-cell">
                <p>Método</p>
              </th>
              <th class="head-cell">
                <p>Recebimento</p>
              </th>
              <th class="head-cell">
                <p>Conta</p>
              </th>
              <th class="head-cell">
                <p>Parcelamento</p>
              </th>
              <th class="head-cell"></th>
              <th class="head-cell"></th>
            </tr>
          </thead>

          <tbody class="data-body">
            <tr class="items">
              <div
                class="item"
                v-for="paymentMethod in paymentMethods"
                :key="paymentMethod.id"
              >
                <td class="cell">
                  <div>{{ paymentMethod.name }}</div>
                  <div v-if="paymentMethod.card_flag_id" class="card-infos">
                    <div class="machine-name">
                      {{ paymentMethod.card_machine?.name }}
                      <span>({{ paymentMethod.card_flag?.name }})</span>
                    </div>
                  </div>
                  <div class="anticipation">
                    {{
                      paymentMethod.anticipation ? 'Com ' : 'Sem '
                    }}antecipação
                  </div>
                </td>
                <td class="cell">
                  <p>{{ paymentMethod.change }}</p>
                </td>
                <td class="cell">
                  <p>{{ paymentMethod.bank_account?.name || '-' }}</p>
                </td>
                <td class="cell">
                  <div v-if="paymentMethod.installments.length > 1">
                    Até {{ paymentMethod.installments.length }} vezes
                    <Info :id="`installments_${paymentMethod.id}`" />
                  </div>
                  <p v-else>Sem parcelamento</p>
                  <b-tooltip
                    :target="`installments_${paymentMethod.id}`"
                    placement="bottom"
                    triggers="hover"
                  >
                    <div class="tax">
                      <div class="tax-title">Taxas</div>
                      <div
                        class="tax-item"
                        v-for="installment in paymentMethod.installments"
                        :key="installment.id"
                      >
                        {{ `${paymentMethod.installments.indexOf(installment) + 1}x` }} -
                        {{
                          !['debit_card', 'card'].includes(paymentMethod.type)
                            ? parseNumberToMoney(installment.value)
                            : `${installment.value
                                .toString()
                                .replace('.', ',')}%`
                        }}
                      </div>
                    </div>
                  </b-tooltip>
                </td>

                <td class="cell">
                  {{
                    paymentMethod.default_payment_method_entries
                      ? 'Rec. padrão'
                      : ''
                  }}
                  {{
                    paymentMethod.default_payment_method_outband
                      ? 'Pag. padrão'
                      : ''
                  }}
                </td>

                <td class="cell margin-left-auto">
                  <div class="receive d-flex">
                    <toggle-button
                      :sync="true"
                      :height="32"
                      :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                      v-model="paymentMethod.status"
                      @input="
                        updatePaymentMethodStatus(
                          paymentMethod,
                          paymentMethod.status
                        )
                      "
                      :disabled="!userHasPermission('FiConMetPag3')"
                    />

                    <Icon tooltip="Duplicar método">
                      <Copy
                        class="copy-icon"
                        @click="copyPaymentMethod(paymentMethod)"
                      />
                    </Icon>
                    <div
                      v-if="
                        userHasPermission('FiConMetPag4') ||
                        userHasPermission('FiConMetPag5')
                      "
                      class="actions"
                    >
                      <Icon tooltip="Editar método">
                        <Edit
                          class="icon"
                          @click="updatePaymentMethod(paymentMethod)"
                        />
                      </Icon>
                      <Icon tooltip="Remover método">
                        <Delete
                          class="icon"
                          @click="openDeleteModal(paymentMethod)"
                        />
                      </Icon>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </tbody>
        </table>

        <div class="add-btn">
          <button
            v-can="'FiConCat2'"
            @click="$bvModal.show('payment-method-modal')"
          >
            Adicionar
          </button>
        </div>
      </div>
    </main>

    <PaymentMethodModal
      :paymentMethod="paymentMethod"
      :cardFlags="cardFlags"
      :cardMachines="cardMachines"
      :bankAccounts="bankAccounts"
      @hidden="hiddenModal"
      @update-table="getPaymentMethods"
    />
  </div>
</template>

<script>
import moment from 'moment'
import api from '../api'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import {
  getCurrentClinic,
  userHasPermission
} from '@/utils/localStorageManager'

export default {
  name: 'cost-center-table',
  components: {
    Delete: () => import('@/assets/icons/delete.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Copy: () => import('@/assets/icons/copy.svg'),
    Loading: () => import('@/assets/icons/loading.svg'),
    'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
    PaymentMethodModal: () =>
      import('@/modules/financial/modals/PaymentMethodModal.vue'),
    Info: () => import('@/assets/icons/info.svg'),
    Icon: () => import('@/components/General/Icon')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      validated: false,
      paymentMethods: [],
      paymentMethod: null,
      cardFlags: [],
      cardMachines: [],
      bankAccounts: [],
      canEdit: userHasPermission('FiConMetPag4')
    }
  },
  mounted() {
    this.getPaymentMethods()
    this.getBankAccounts()
    this.getCardFlags()
    this.getCardMachines()
  },
  methods: {
    moment,
    userHasPermission,
    parseNumberToMoney,
    async getPaymentMethods() {
      const isLoading = this.$loading.show()
      api
        .getPaymentMethods(this.clinic.id)
        .then(({ data }) => (this.paymentMethods = data))
        .catch(err => this.$toast.error(err.message))
        .finally(() => (isLoading.hide()))
    },
    updatePaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod
      setTimeout(() => {
        this.$bvModal.show('payment-method-modal')
      }, 500)
    },
    copyPaymentMethod(paymentMethod) {
      this.paymentMethod = {
        ...paymentMethod,
        id: null,
        name: `${paymentMethod.name} cópia`
      }
      this.$bvModal.show('payment-method-modal')
    },
    getBankAccounts() {
      this.api.getBankAccount(this.clinic.id).then(res => {
        this.bankAccounts = res.data.map(el => ({
          ...el,
          label: `${el.name}`,
          value: `${el.id}`
        }))
        this.bankAccounts.unshift({ label: `Selecionar...`, value: null })
      })
    },
    getCardFlags() {
      const isLoading = this.$loading.show()
      api
        .getCardFlags(this.clinic.id)
        .then(({ data }) => {
          this.cardFlags = data.map(el => ({
            label: el.name,
            value: el.id
          }))
          this.cardFlags.unshift({ label: 'Selecionar...', value: null })
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (isLoading.hide()))
    },
    getCardMachines() {
      const isLoading = this.$loading.show()
      api
        .getCardMachines(this.clinic.id)
        .then(({ data }) => {
          this.cardMachines = data.map(el => ({
            label: el.name,
            value: el.id
          }))
          this.cardMachines.unshift({ label: 'Selecionar...', value: null })
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (isLoading.hide()))
    },
    openDeleteModal(paymentMethod) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover o método <span>${paymentMethod?.name}</span>?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.deletePaymentMethod(paymentMethod.id)
        }
      })
    },
    deletePaymentMethod(id) {
      const isLoading = this.$loading.show()
      api
        .deletePaymentMethod(id)
        .then(() => {
          this.$toast.success('Método removido com sucesso!')
          this.paymentMethods = this.paymentMethods.filter(el => el.id !== id)
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (isLoading.hide()))
    },
    async updatePaymentMethodStatus(paymentMethod, status) {
      if (this.hasDuplicatedActivation(paymentMethod)) return
      const isLoading = this.$loading.show()
      try {
        await api.updatePaymentMethod(paymentMethod.id, { status })
        this.$toast.success('Método atualizado com sucesso!')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    hasDuplicatedActivation(paymentMethod) {
      if (paymentMethod.type !== 'card' || !paymentMethod.status) return false
      const duplicated = this.paymentMethods.find(
        pm =>
          pm.id !== paymentMethod.id &&
          pm.status &&
          pm.type === paymentMethod.type &&
          pm.card_flag_id === paymentMethod.card_flag_id &&
          pm.card_machine_id === paymentMethod.card_machine_id
      )
      if (duplicated) {
        setTimeout(() => (paymentMethod.status = false), 500)
        this.$swal({
          icon: 'warning',
          html: `<div class="body-alert">
              <h3>Conflito de métodos!</h3>
              <p>Não é possível ativar dois métodos de pagamento com a mesma configuração de máquina e bandeira ao mesmo tempo.</p>
              <br/>
              <p>Para ativar este método primeiro desative o método <span>${
                duplicated?.name
              } ${
            duplicated?.card_machine?.name
              ? ` - ${duplicated?.card_machine?.name}`
              : ''
          } ${
            duplicated?.card_flag?.name
              ? `(${duplicated?.card_flag?.name})`
              : ''
          }</span>, não esqueça de conferir se o modo de antecipação a ser ativado está correto.</p>
            </div>`,
          confirmButtonColor: '#305BF2',
          confirmButtonText: 'Fechar'
        })
        return true
      }
    },
    hiddenModal() {
      this.paymentMethod = null
      this.getPaymentMethods()
    },
    getPositionLabel(type, position) {
      switch (type) {
        case 'card':
          return position === 0 ? 'Débito' : `${position}x`
        case 'bank_slip':
        default:
          return `${position + 1}x`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-method-table {
  padding: 40px 10px 0px 10px;
  width: 100%;
  height: 100%;
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
    margin-bottom: 27px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .subtitle {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--type-active);
    padding-bottom: 24px;
    text-align: left;
    flex-direction: row;
    gap: 8px;

    .icon {
      height: 24px;
      width: 24px;
      stroke: var(--neutral-500);
    }
  }

  .outages {
    box-sizing: border-box;
    border-radius: 8px;

    .order-body {
      border-radius: 8px;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: var(--dark-blue);
        text-align: left;
      }
    }

    .add-btn {
      text-align: center;
      padding: 24px 0;
      border-left: 1px solid var(--neutral-200);
      border-right: 1px solid var(--neutral-200);
      border-bottom: 1px solid var(--neutral-200);
      border-radius: 0 0 7px 7px;

      button {
        transition: 0.3s;
        border: 2px solid var(--blue-500);
        padding: 8px 68px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: var(--blue-500);
        border-radius: 8px;

        &.saveBtn {
          color: var(--blue-500);
          border: 2px solid var(--blue-500);
        }

        &.cancel {
          color: var(--light-orange-200);
          border: 2px solid #ffdccc;
        }
      }

      button:hover {
        transition: 0.4s;
        color: white;
        background-color: var(--blue-500);
      }
    }

    table {
      table-layout: fixed !important;
      width: 100% !important;
      border-collapse: collapse !important;
    }

    .head-cell {
      text-align: left !important;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        color: var(--type-active);
        text-align: left;
      }
    }

    .cell {
      white-space: normal;
      display: flex;
      align-items: left;
      flex-direction: column;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        color: var(--type-active);
        text-align: left;
      }
    }

    .flexComponent {
      display: flex;
    }

    .flexRow {
      flex-direction: row;
      justify-content: space-around;
    }

    .status-toggle-create {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .center-options .optionsDiv {
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: var(--type-active);
      }

      svg {
        fill: var(--neutral-500);
      }
    }

    .value-tag {
      max-width: 70%;
      padding: 8px 16px;
      border-radius: 50px;
      background-color: var(--neutral-200);
      color: #000;
      text-align: center !important;
      overflow: hidden;

      &.active {
        background-color: var(--light-orange-200);
      }

      &.negative {
        background-color: #fedad7;
      }
    }

    .data-head {
      tr {
        display: grid;
        grid-template-columns: 2fr repeat(5, 1fr);
        padding: 12px 24px;
        background: var(--neutral-100);
        color: #525c7a;
        flex-direction: row;
        border-radius: 7px 7px 0px 0px !important;
        border: 1px solid var(--neutral-200);
      }
    }

    .data-body {
      .items {
        display: flex;
        flex-direction: column;
        position: relative;

        .item {
          list-style: none;
          padding: 24px;
          display: grid;
          align-items: center;
          grid-template-columns: 2fr repeat(5, 1fr);
          border-bottom: 1px solid var(--neutral-200);
          border-left: 1px solid var(--neutral-200);
          border-right: 1px solid var(--neutral-200);

          .times-tag {
            margin: 0 auto;
            border-radius: 50px;
            background-color: var(--neutral-200);
            color: #000;
            text-align: center;

            &.active {
              background: var(--light-orange-200);
            }

            &.negative {
              background: #fedad7;
            }
          }

          .receive {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: none !important;
            text-align: left;
            align-items: center;
            gap: 8px;

            .options {
              cursor: pointer;
              width: 24px;
              height: 28px;
              position: relative;
              display: inline-block;

              .more-icon {
                width: 24px;
                height: 24px;
                stroke: var(--neutral-500);
                transition: all 0.5s;
                cursor: pointer;
              }

              .menu {
                width: 160px;
                position: absolute;
                top: 28px;
                right: 0;
                background: var(--neutral-000);
                border: 1px solid var(--neutral-200);
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
                  0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                border-radius: 8px;
                padding: 24px;
                opacity: 0;
                transition: all 0.5s;
                flex-direction: column;
                align-items: start;
                display: none;

                .mtb-12 {
                  margin: 12px 0;
                }

                .btn {
                  font-weight: 600;
                  margin-bottom: 16px;

                  &.remove {
                    color: var(--states-error);
                  }
                }
              }

              &:hover {
                .more-icon {
                  transform: rotate(90deg);
                }

                .menu {
                  opacity: 1;
                  display: flex;
                  z-index: 10;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tax {
  font-family: 'Nunito Sans';
  font-style: normal;
  text-align: left;
  color: var(--type-active);
}

.tax-title {
  font-weight: 700;
  font-size: 16px;
}

.tax-item {
  text-align: left;
}

.margin-left-auto {
  margin-left: auto;
}

.delete-btn {
  height: 37px !important;
  font-weight: 800 !important;
  margin-left: 20px;
  border: none !important;
  border-radius: 8px !important;
}

.card-infos {
  display: flex;
  gap: 5px;
  font-weight: 400;
  font-size: 12px;

  .machine-name {
    color: var(--blue-500);
    span {
      color: var(--type-active);
    }
  }
}
.anticipation {
  font-weight: 400;
  font-size: 12px;
  color: var(--type-placeholder);
}

.copy-icon {
  cursor: pointer;
}
.actions {
  justify-content: right;
  display: flex;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;
  }
}
</style>
